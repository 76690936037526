<template>
  <div class="member-by-genders-stats">
    <page-header
      :title="byFamily ? 'Familles par genre' : 'Membres par genre'"
      icon="fas fa-chart-bar"
      :links="getLinks()"
    >
    </page-header>
    <div>
      <b-row>
        <b-col cols="9" ref="printMe">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <div v-show="!isLoading(statsLoading)" ref="excelMe">
            <table class="table table-bordered">
              <tr>
                <th class="header-odd">Genre</th>
                <th
                  v-for="year of years" :key="year"
                  class="text-center"
                  :class="yearClass(year)"
                >
                  {{ getYearName(year) }}
                </th>
              </tr>
              <tr>
                <th class="header-odd"></th>
                <th
                  v-for="year of years" :key="year"
                  class="text-center"
                  :class="yearClass(year)"
                >
                  {{ headerSum(year) }}
                  <div v-if="diff && getTotalDiff(year)" class="ignore">
                    <div class="diff">{{ getTotalDiff(year) }}</div>
                  </div>
                </th>
              </tr>
              <tr v-for="obj of genders" :key="obj">
                <td class="text-left cell-odd">
                  {{ obj }}
                </td>
                <td
                  v-for="cell of getObjectRow(obj)"
                  :key="cell.index"
                  class="number text-center"
                  :class="cellClas(cell.index)"
                >
                  {{ getCellValue(cell) }}
                  <div v-if="diff && getDiff(obj, cell)" class="ignore">
                    <div class="diff">{{ getDiff(obj, cell) }}</div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox name="showPercentage" id="showPercentage" v-model="showPercentage">
            En pourcentage
          </b-form-checkbox>
          <br />
          <b-form-checkbox name="diff" id="diff" v-model="forceDiff" :disabled="selectedSchoolYears.length <= 1">
            Affichage évolution
          </b-form-checkbox>
          <br /><br />
          <active-school-years-multi-select v-model="selectedSchoolYears"></active-school-years-multi-select>
          <br /><br />
          <b-checkbox
            id="distinctYears"
            v-model="distinctYears"
            :disabled="displayedSchoolYears.length <= 1"
          >
            voir par année
          </b-checkbox>
          <br /><br />
          <members-by-select v-model="selectedMembersBy"></members-by-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ActiveSchoolYearsMultiSelect from '@/components/SchoolYears/ActiveSchoolYearsMultiSelect.vue'
import MembersBySelect from '@/components/Stats/MembersBySelect'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'members-by-gender',
  mixins: [BackendMixin],
  components: {
    MembersBySelect,
    ActiveSchoolYearsMultiSelect,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'members-by-gender',
      selectedSchoolYears: [],
      selectedMembersBy: null,
      statsByYear: new Map(),
      gendersMap: new Map(),
      rows: [],
      sums: [],
      showPercentage: false,
      forceDiff: true,
      byFamily: false,
      distinctYears: true,
    }
  },
  computed: {
    diff() {
      return this.selectedSchoolYears.length > 1 && this.forceDiff
    },
    displayedSchoolYears() {
      const schoolYears = [].concat(this.selectedSchoolYears)
      return schoolYears.sort((a, b) => compareNumbers(a.startYear, b.startYear))
    },
    years() {
      let years = this.displayedSchoolYears.map(year => '' + year.id)
      if (this.distinctYears) {
        return years
      } else {
        if (years.length) {
          return [years.join('-')]
        }
        return []
      }
    },
    genders() {
      const genders = Array.from(this.gendersMap.keys())
      return genders.sort()
    },
  },
  mounted() {
    this.onLoaded()
  },
  watch: {
    selectedSchoolYears: function() {
      this.onLoaded()
    },
    selectedMembersBy: function() {
      this.onLoaded()
    },
    selectedLocationType: function() {
      this.onLoaded()
    },
    distinctYears: function() {
      this.onLoaded()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadStats()
    },
    async loadStats() {
      if (this.selectedMembersBy) {
        this.startLoading(this.loadingName)
        const statsByYear = new Map()
        for (const year of this.years) {
          const url = '/stats/api/members-by-gender/' + this.selectedMembersBy.id + '/' + year + '/'
          const backendApi = new BackendApi('get', url)
          try {
            const resp = await backendApi.callApi()
            statsByYear.set(year, resp.data)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
        this.statsByYear = statsByYear
        this.buildRows()
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'membres-par-genre-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const diff = this.forceDiff
      this.forceDiff = false
      const that = this
      setTimeout(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = 'membres-par-genre-' + moment().format('YYYY-MM-DD')
          const docContent = that.$refs.excelMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            that.addError(this.getErrorText(err))
          }
          that.forceDiff = diff
        },
        200
      )
    },
    getObjectRow(obj) {
      const rowIndex = this.gendersMap.get(obj)
      let row = this.rows[rowIndex]
      return row.map((elt, index) => {
        return { value: elt, index: index, }
      })
    },
    buildRows() {
      const objectsMap = new Map()
      const rows = []
      const newRow = new Array(this.years.length).fill(0)
      this.sums = new Array(this.years.length).fill(0)
      this.byFamily = false
      for (let yearIndex = 0; yearIndex < this.years.length; yearIndex++) {
        const year = this.years[yearIndex]
        const stats = this.statsByYear.get(year)
        for (const elt of stats) {
          let row
          const obj = elt.name || ''
          if (!objectsMap.has(obj)) {
            objectsMap.set(obj, rows.length)
            row = [].concat(newRow)
            rows.push(row)
          } else {
            const rowIndex = objectsMap.get(obj)
            row = rows[rowIndex]
          }
          row[yearIndex] = elt.count
          if ((elt.field || '') === 'entity') {
            this.byFamily = true
          }
        }
        this.sums[yearIndex] = sum(rows.map(cell => cell[yearIndex]))
      }
      this.rows = rows
      this.gendersMap = objectsMap
    },
    yearClass(year) {
      const yearIndex = this.years.indexOf(year)
      return (yearIndex % 2) ? 'header-odd' : ''
    },
    getYearName(year) {
      if (this.distinctYears) {
        const years = this.displayedSchoolYears.filter(elt => elt.id === +year)
        if (years.length) {
          return years[0].name
        } else {
          return '-'
        }
      } else {
        return this.displayedSchoolYears.map(elt => elt.name).join(' - ')
      }
    },
    cellClas(index) {
      return (index % 2) ? 'cell-odd' : ''
    },
    headerSum(year) {
      const yearIndex = this.years.indexOf(year)
      return this.sums[yearIndex]
    },
    getCellValue(cell) {
      if (this.showPercentage) {
        if (this.sums[cell.index]) {
          const value = Math.round(10000 * cell.value / this.sums[cell.index]) / 100
          return '' + value + '%'
        }
        return ''
      }
      return cell.value
    },
    getTotalDiff(year) {
      const yearIndex = this.years.indexOf(year)
      if (yearIndex > 0) {
        const value = this.sums[yearIndex]
        const prevValue = this.sums[yearIndex - 1]
        if (prevValue) {
          const diff = Math.round(10000 * (value - prevValue) / prevValue) / 100
          const prefix = (diff > 0) ? '+' : ''
          return prefix + diff + '%'
        }
      }
      return ''
    },
    getDiff(elt, cell) {
      const row = this.getObjectRow(elt)
      if (cell.index > 0) {
        const prevValue = row[cell.index - 1].value
        if (prevValue) {
          const diff = Math.round(10000 * (cell.value - prevValue) / prevValue) / 100
          const prefix = (diff > 0) ? '+' : ''
          return prefix + diff + '%'
        }
      }
      return ''
    },
  },
}
</script>

<style scoped lang="less">
.number {
  text-align: right;
}
tr th {
  background: #444;
  color: #fff;
}
tr th.header-odd {
 background: #222;
}
tr:nth-of-type(odd) {
  background: #eee;
}
tr:nth-of-type(even) {
  background: #fff;
}
tr:nth-of-type(odd) .cell-odd {
  background: #ccc;
}
tr:nth-of-type(even) .cell-odd {
  background: #ddd;
}
.diff {
  font-size: 11px;
  background: #111;
  color: #fff;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 8px;
}
</style>
